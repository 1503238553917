<template>
  <div v-if="isDataLoaded">
    <br />

    <v-row>
      <v-col cols="12" sm="5">
        <event-details :items="items" :fromAdmin="fromAdmin" />
      </v-col>

      <v-col cols="12" sm="7">
        <number-of-tickets :items="items" :chartotal="chartotal"/>
      </v-col>
    </v-row>
    <br />

    <v-row>
      <v-col cols="12" sm="6">
        <revenue :items="chartData" :payout="items" :chartotal="chartotal" />
      </v-col>

      <v-col cols="12" sm="6">
        <promo-codes :items="promocodeData" />
      </v-col>
    </v-row>

    <v-row v-if="isAdmin">
      <v-col cols="12" sm="12">
        <adds-on :items="items.addons" :tickets="ticketData" :eventaddons="eventAddons" :id="items._id"/>
      </v-col>
    </v-row>

    <!-- <br />

    <v-card>
      <v-card-title>
        Your Event Link
      </v-card-title>

      <v-card-text>
        <v-text-field
          :dense="$vuetify.breakpoint.xs"
          outlined
          readonly
          append-icon="mdi-content-copy"
          @click:append="$copy(getUrl())"
          :value="getUrl()"
        >
        </v-text-field>
      </v-card-text>
    </v-card> -->

    <br />

     <v-row>
      <v-col cols="12" sm="12">
        <event-share :event="items" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PromoterEventService, PromocodeService } from '@services';

import Revenue from './revenue';
import PromoCodes from './promocode';
import NumberOfTickets from './number-of-tickets';
import EventDetails from './event-details';
import EventShare from './event-share.vue';
import AddsOn from './adds-on.vue'

export default {
  components: {
    Revenue,
    PromoCodes,
    NumberOfTickets,
    EventDetails,
    EventShare,
    AddsOn,
    isAdmin: false,
  },

  props: {
    fromAdmin: { type: Boolean, default: false },
  },

  data() {
    return {
      items: {},
      chartData: [],
      promocodeData: [],
      chartotal: 0,
      isDataLoaded: false,
      ticketData: [],
      eventAddons: [],
    };
  },

  created() {
    if (this.$user.role === 'admin') {
      this.isAdmin = true;
    }
    this.getEvents();
  },

  methods: {
    async getEvents() {
      const data = await PromoterEventService.getEventDashboard(this.$route.params.id);
      if (data) {
        this.promocodeData = await PromocodeService.getPromocodeCountByEventId(data.event._id)
        this.items = data.event;
        this.chartotal = data.chartotal;
        this.chartData = data.chartData;
        if(data && data.event && data.event.addson) {
          this.eventAddons = data.event.addson;
        }
        if(data.event &&  data.event.ticketassignments &&  data.event.ticketassignments.length) {
          this.ticketData = data.event.ticketassignments;
        }
        this.items.totalAvailableTicketsPercent = Math.round(
          (data.event.totalAvailableCount * 100) / (data.event.totalAvailableCount + data.event.remainingTicketCount)
        );
        this.items.remainingTicketCountPercent = Math.round(
          (data.event.remainingTicketCount * 100) / (data.event.totalAvailableCount + data.event.remainingTicketCount)
        );
        this.isDataLoaded = true;
      }
    },
    getUrl() {
      let url = `${window.location.protocol}//${window.location.host}`;
      // const port = window.location.port;
      // if (port) url += `:${port}`;
      return `${url}/${this.items.slug}?utm_source=link&utm_medium=social&utm_campaign=tickettext`;
    },
  },
};
</script>

<style lang="scss">
.v-data-table-small {
  td,
  th {
    font-size: 0.75rem !important;
    height: 32px !important;
  }
}
</style>
