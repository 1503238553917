<template>
  <v-card class="h-100">
    <v-card-title>
      Promocodes
    </v-card-title>
    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.tickets`]="{ item }">
        <div>{{ item.usag || 0 }}/{{ item.numberOfRedemptions }}</div>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span v-if="item.discountedPrice">{{ item.discountedPrice | currency }}</span>
        <span v-if="item.discountedPercentage">{{ item.discountedPercentage }}%</span>
      </template>
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ item.startsAt | dateTimeshort }}
      </template>
      <template v-slot:[`item.expiresAt`]="{ item }">
        {{ item.expiresAt | dateTimeshort }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'code',
        },
        { text: 'Discount', value: 'amount', sortable: false },
        { text: 'Uses', value: 'tickets', sortable: false },
        { text: 'Start date', value: 'startsAt', sortable: false },
        { text: 'End date', value: 'expiresAt', sortable: false },
      ],
    };
  },
};
</script>
