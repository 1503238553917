<template>
  <v-card class="h-100">
    <v-card-title>
      Add Ons
    </v-card-title>
    <v-data-table class="v-data-table-small" :headers="headers" :items="data" hide-default-footer>
      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.soldQuantity }}/{{ item.quantity }}
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.soldQuantity }}/{{ item.quantity }}
      </template>
      <template v-slot:[`item.valueName`]="{ item }">
        <span v-if="valueName">{{ item.valueName }}</span>
        <span v-else>-</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    items: Array,
    id: String,
    tickets: Array,
    eventaddons: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'Item',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Supplier', value: 'supplierName', sortable: false },
        { text: 'Add Ons Value', value: 'valueName', sortable: false },
        { text: 'Quantity', value: 'quantity', sortable: false },
      ],
      data: [],
    };
  },
  created() {
    this.prepareAddons();
  },
  methods: {
    prepareAddons() {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        let addonQty = 0;
        let addonSoldQty = 0;
        if(element.attributes && element.attributes.length) {
          for(let attrInx = 0; attrInx < element.attributes.length; attrInx++) {
            const eventAddon = this.eventaddons.find((addon) => addon.addon === element._id);
            addonQty += eventAddon.attributes[attrInx].quantity;
            addonSoldQty = 0;
            if(this.tickets && this.tickets.length) {
              for(let ticketInx = 0; ticketInx < this.tickets.length; ticketInx++) {
                let ticketElement = this.tickets[ticketInx];
                if(ticketElement && ticketElement.addsons && ticketElement.addsons.length) {
                  for(let addonInx = 0; addonInx < ticketElement.addsons.length; addonInx++){
                    let addonElement = ticketElement.addsons[addonInx];
                    if(addonElement.addon === element._id) {
                      addonSoldQty += 1;
                    }
                  }
                  
                }
              }
            }
          }
        }
        // const eventAddon = element.events.find((event) => event.event === this.id);
        this.data.push({
          name: element.name,
          valueName: element.valueName,
          quantity: addonQty,
          soldQuantity: addonSoldQty,
          price: 0,
          supplierName: element.suppliers.name,
        })
      }
    },
  }
};
</script>
