<template>
  <v-card class="h-100">
    <v-card-title>
      Event details
      <v-spacer />
      <span class="text-subtitle-1">
        Status: <b class="primary--text"> {{ items.status | capitalize }}</b></span
      >
    </v-card-title>
    <!-- <v-card-title>
      <v-spacer />
      <span class="text-subtitle-1">
        Status: <b class="primary--text"> {{ items.status | capitalize }}</b></span
      >
    </v-card-title> -->
    <v-card-text class="px-0">
      <v-list-item three-line>
        <v-list-item-avatar rounded size="100">
          <v-img :src="$get400Image(items.image, items.imageUserId,items._id)" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ items.title }}</v-list-item-title>
          <v-list-item-subtitle>
            <b class="mr-1">Starts on:</b>{{ items.startsAt | dateWithDay }}
            <br />
            <b class="mr-2">Ends on:</b>&emsp;{{ items.endsAt | dateWithDay }}
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="items.venues">
            <b class="mr-1">Venue:</b> {{ items.venues.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-actions class="justify-end">
      <!-- <v-btn width="100" color="primary" :loading="loading" outlined @click="exportExcel()">
        Report
      </v-btn> -->
      <v-btn width="100" color="primary" outlined :to="{ name: 'visitor.event', params: { id: items.slug } }">
        Preview
      </v-btn>
      <v-btn
        width="100"
        color="primary"
        :to="{ name: fromAdmin ? 'admin.events.edit' : 'promoter.events.edit', params: { id: items.slug } }"
        >Edit</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    items: Object,
    fromAdmin: { type: Boolean, default: false },
    loading: false,
  }
};
</script>
