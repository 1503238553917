<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title>
        Share Event Link
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" :sm="type === 'Custom' ? '5' : '10'">
            <v-autocomplete
              :items="types"
              item-text="title"
              item-value="value"
              label="Type"
              outlined
              @change="changeType(type)"
              dense
              clearable
              v-model="type"
              placeholder="e.g active"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="5" v-if="type === 'Custom'">
            <v-text-field
              outlined
              dense
              clearable
              @keydown.space="preventLeadingSpace"
              name="maximum"
              label="Custom value"
              :rules="[
                v => !!v || 'Value is required',
                v => (v && v.length < 20) || 'Custom value must less than 20 character',
              ]"
              v-model="selectedType"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn  color="primary" @click="generateLink()">Generate Link</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" v-show="showLink">
            <v-text-field
              :dense="$vuetify.breakpoint.xs"
              outlined
              id="event-share-link"
              readonly
              append-icon="mdi-content-copy"
              @click:append="$copy(getUrl())"
              :value="getUrl()"
            >
          </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    event: Object,
  },
  data() {
    return {
      types: [
        { title: 'Link', value: 'link' },
        { title: 'Facebook', value: 'facebook' },
        { title: 'Twitter', value: 'twitter' },
        { title: 'Email', value: 'email' },
        { title: 'Custom', value: 'Custom' },
      ],
      type: 'link',
      selectedType: 'link',
      linkUtmValue: 'link',
      showLink: true,
      valid: false
    };
  },
  async mounted() {},
  methods: {
    getUrl() {
      let url = `${window.location.protocol}//${window.location.host}`;
      return `${url}/${this.event.slug}?utm_source=${this.linkUtmValue}&utm_medium=social&utm_campaign=tickettext`;
    },
    changeType(value) {
      this.showLink = false;
      if (value !== 'Custom') {
        this.selectedType = value;
      } else {
        this.selectedType = '';
      }
    },
    generateLink() {
      if (this.$refs.form.validate()) {
        this.linkUtmValue = this.selectedType;
        this.showLink = true;
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        },0)
      }
    },
  },
};
</script>
