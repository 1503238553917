<template>
  <v-card>
    <v-card-title>
      Net Sales
      <v-spacer />
      <b class="primary--text"> {{ chartotal | currency }}</b>
    </v-card-title>

    <v-card-text class="px-0">
      <v-responsive :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4">
        <div v-if="noData" class="center text-sm-h5 w-100 text-center">
          Currently there are no data for this event
        </div>  
        <div v-else id="chart_div" class="h-100"></div>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    items: Array,
    payout: Object,
    chartotal: Number
  },
  data() {
    return {
      tab: 0,
      noData: false
    };
  },
  async mounted() {
    
    if (!window.google?.charts) {
      await this.$loadScript('https://www.gstatic.com/charts/loader.js');
      window.google.charts.load('47', {
        packages: ['corechart', 'bar']
      });
      window.google.charts.setOnLoadCallback(this.generateChart);
      return
    }
    this.generateChart()
  },
  methods: {
    generateChart() {
      if (this.items.length) {
      var data = window.google.visualization.arrayToDataTable([['Month', 'Revenue'], ...this.items]);

      var options = {
        // title: 'Monthly Coffee Production by Country',
        vAxis: { title: 'Revenue (in pound)' },
        hAxis: { title: 'Weeks' },
        colors: ['#A6CEE3'],
      };

      var chart = new window.google.visualization.ColumnChart(document.getElementById('chart_div'));
      chart.draw(data, options);
      }else {
      this.noData = true;
    }
    },
  },
};
</script>
