<template>
  <v-card class="h-100">
    <v-card-title>
      Number of tickets
    </v-card-title>

    <v-card-text class="px-0">
      <v-row>
        <v-col cols="12" sm="4" md="3" class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="10"
            :value="items.totalAvailableTicketsPercent"
            color="primary"
          >
            <b>{{ items.totalAvailableCount }}</b>
          </v-progress-circular>

          <div class="mt-2">Tickets Sold</div>
        </v-col>
        <v-col cols="12" sm="4" md="3" class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="10"
            :value="items.remainingTicketCountPercent"
            color="primary"
          >
            <b>{{ items.remainingTicketCount }}</b>
          </v-progress-circular>

          <div class="mt-2">Tickets Remaining</div>
        </v-col>
        <v-col cols="12" sm="4" md="3" class="text-center">
          <v-progress-circular :rotate="360" :size="100" :width="10" :value="items.totalRefundCount" color="primary">
            <b>{{ items.totalRefundCount }}</b>
          </v-progress-circular>

          <div class="mt-2">Ticket Refunded</div>
        </v-col>

        <v-col cols="12" sm="12" md="3" class="position-relative">
          <div class="center w-100 text-center">
            <div class="text-bold">Cash Taken</div>
            <div class="primary--text text-h5 text-bold">{{ chartotal | currency }}</div>
            <div class="text-bold" v-if="$user.role === 'admin'">Booking Fees</div>
            <div class="primary--text text-h5 text-bold" v-if="$user.role === 'admin'">{{ items.bookingFee | currency }}</div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
     <v-card-actions class="justify-end">
      <v-btn color="primary" :loading="loading" outlined @click="exportExcel()">
        Sales by Ticket Category
      </v-btn>
       <v-btn color="primary" :loading="loading" outlined @click="exportExcelTicketCodes()">
        Export Ticket Codes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import { SeatingPlan } from '@services';
export default {
  props: {
    items: Object,
    chartotal: Number
  },
  methods: {
    async exportExcel() {
      this.loading = true;
      const exportdata = await SeatingPlan.exportReport(this.items._id);
      const title = this.items.title.replace(/[@!&\/\\#,+()$~%.'":^*?<>{}]/g,'')
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}_${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading = false;
    },

    async exportExcelTicketCodes() {
      this.loading = true;
      const exportdata = await SeatingPlan.exportTicketCodeReport(this.items._id);
      const title = this.items.title.replace(/[@!&\/\\#,+()$~%.'":^*?<>{}]/g,'')
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}_ticketcodes_${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading = false;
    },
  }
};
</script>
